/* Google Font Include */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap');


@font-face {
    font-family: qalamQuranMajeed;
    src: url(../fonts/qalam_quran_majeed.ttf);
  }

@font-face {
    font-family: qalammaJeed;
    src: url(../fonts/qalammajeed.ttf);
  }
/* Normalization */


/* font-family: 'Roboto', sans-serif; */
/* font-family: 'Hind Siliguri', sans-serif; */
/* font-family: 'Kalpurush', sans-serif; */
/* font-family: 'Kalpurush', sans-serif; */

body {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    line-height: .9;
    font-weight: 700;
    color: #34374c;
    font-family: 'Roboto', sans-serif;
}

p {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: #3f4360;
    line-height: 1.2222;
}

ul,
ol,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

a,
a:hover,
a:focus {
    display: inline-block;
    outline: none;
    text-decoration: none;
}

a:hover,
a:focus {
    color: inherit;
}

a {
    -webkit-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
}

img {
    width: 100%;
    height: auto;
}

button:focus {
    outline: 1px dotted transparent !important;
}


/*==============  Common Content =============**/

.common-tittle {
    color: #34374c;
    font-size: 49px;
    padding: 0 0 75px;
    text-align: center;
}

.white {
    color: #fff !important;
}

.common-tittle.border {
    border-bottom: 1px solid #d4d6e3;
    margin-bottom: 35px;
    padding-bottom: 26px;
}


/*==============  Margin and padding settings =============**/

.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.plr-2-5 {
    padding-left: 0;
    padding-right: 0;
    /* padding-left: 2.5px;
    padding-right: 2.5px; */
}
.plr-5 {
    padding-left: 5px;
    padding-right: 5px;
    padding-right: 2.5px;
}

.mlr-2-5 {
    margin-left: 2.5px;
    margin-right: 2.5px;
}
 .mb-20{
     margin-bottom: 20px !important;
 }
 .w-100{
     width: 100%;
 }
 .mb-0{
     margin-bottom: 0 !important;
 }
 .mr-15{
     margin-right: 15px;
 }
 .max-width-100{
  max-width: 100% !important;
 }
 .max-width-830{
  max-width: 830px !important;
 }
 .btn:focus,
 .btn.focus{
     box-shadow: none;
 }
/*============== sticky-wrapper =============**/

.is-sticky .navbar-wrapper {
    padding: 0px;
    z-index: 999;
}


/********Scroll Up*******/

.scrollup {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 40px;
    color: #ffffff;
    display: none;
    font-size: 40px;
    height: 40px;
    line-height: 35px;
    position: fixed;
    right: 40px;
    text-align: center;
    width: 40px;
    -webkit-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease
}

.scrollup:hover {
    color: #fff;
}