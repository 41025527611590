
@media only screen and (min-width:0px) and (max-width:767px) {
    .wrapper{
        padding: 0 !important;
    }
    .mlr-2-5 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
  .contact-block-wrap{
      /* border: 1px solid #f00; */
  } 
  .contact-block {
    padding: 0 !important;
    box-shadow: none !important;
}
.contact-block .form-group {
    padding: 0 !important;
}
.header-logo {
    max-width: 65vw !important;
}


}
