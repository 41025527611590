/** initial setup **/
.nano {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: calc(100vh - 100px - 175px) !important;
    /* height: calc(100vh - 100px - 175px) !important; */
}

.nano-dua {
    position: relative;
    width: 100%;
    overflow: hidden;
    /* height: calc(100vh - 190px - 60px) !important; */
    height: calc(100vh - 100px - 60px) !important;
}

@media screen and (-moz-images-in-menus:0) {
    .nano {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: calc(100vh - 100px - 120px) !important;
        border: 1px solid #f00;
    }
    .nano-dua {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: calc(100vh - 100px - 60px) !important;
    }

}
.nano-sidebar {
    height: 100% !important;
}

.nano>.nano-content {
    position: absolute;
    /* overflow: scroll;
    overflow-x: hidden; */
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.nano>.nano-content:focus {
    outline: thin dotted;
}

.nano>.nano-content::-webkit-scrollbar {
    display: none;
}

.has-scrollbar>.nano-content::-webkit-scrollbar {
    display: block;
}

.nano>.nano-pane {
    background: #dfdfdf;
    position: absolute;
    width: 2px;
    right: 0;
    top: 0;
    bottom: 0;
    visibility: hidden\9;
    /* Target only IE7 and IE8 with this hack */
    opacity: .01;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

.nano>.nano-pane>.nano-slider {
    background: #dfdfdf;
    position: relative;
    margin: 0 1px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}
.nano:hover>.nano-pane,
.nano-pane.active,
.nano-pane.flashed {
    visibility: visible\9;
    /* Target only IE7 and IE8 with this hack */
    opacity: 0.99;
}
